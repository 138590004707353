// Global packages and components
import { clsx } from 'clsx'
import { useState } from 'react'

// Types
interface InputProps {
  name?: string
  type?: 'text' | 'email' | 'number' | 'tel' | 'password' | 'date' | 'search'
  label?: string
  labelHidden?: boolean
  maxLength?: number
  minLength?: number
  max?: number | string
  min?: number | string
  step?: number | string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  prependIcon?: boolean
  placeholder?: string
  value?: string
  error?: string
  autocomplete?: string
  ariaLabel?: string
  form?: string
  inputMode?: 'text' | 'numeric' | 'tel' | 'search' | 'email' | 'url'
  pattern?: string
  onChange?: (e: string) => void
  onBlur?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

// Main export
const Input = ({
  name,
  type = 'text',
  label,
  labelHidden,
  maxLength,
  minLength,
  max,
  min,
  step,
  disabled = false,
  readOnly = false,
  required = false,
  placeholder,
  value,
  error,
  autocomplete,
  ariaLabel,
  form,
  inputMode = 'text',
  pattern,
  onChange,
  onBlur,
  onKeyDown,
}: InputProps) => {
  const [inputType, setInputType] = useState(type)
  const kebabCase = (string: string) =>
    string
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()

  return (
    <div className="relative flex flex-col gap-2">
      <label
        className={clsx('font-medium', {
          'sr-only': labelHidden,
        })}
      >
        {label}
        {required && <span className="text-red-600">*</span>}
      </label>
      <div className="relative">
        <input
          type={inputType}
          className={clsx(
            'block w-full appearance-none rounded-lg border bg-white p-3 outline-none',
            {
              'border-red-600': error,
              'border-neutral-300 focus:border-teal-green': !error,
            }
          )}
          name={name}
          id={name && kebabCase(name)}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          min={min}
          max={max}
          step={step}
          autoComplete={autocomplete}
          aria-label={ariaLabel}
          disabled={disabled}
          form={form}
          readOnly={readOnly}
          required={required}
          inputMode={inputMode}
          pattern={type == 'number' ? '[0-9]*' : pattern ? pattern : undefined}
          onChange={e => (onChange ? onChange(e.target.value) : undefined)}
          onBlur={() => (onBlur ? onBlur() : undefined)}
          onKeyDown={e => (onKeyDown ? onKeyDown(e) : undefined)}
        />
        {type == 'password' && (
          <button
            type="button"
            tabIndex={-1}
            className="outline:none absolute right-3 top-0 h-full"
            onClick={() =>
              setInputType(inputType == 'text' ? 'password' : 'text')
            }
          >
            {inputType == 'text' ? (
              <span className="font-semibold text-forest-green">Hide</span>
            ) : (
              <span className="font-semibold text-forest-green">Show</span>
            )}
          </button>
        )}
      </div>
      {error && <p className="mb-0 pt-2 text-xs text-red-600">{error}</p>}
    </div>
  )
}

export default Input
